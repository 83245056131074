import React, {useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom"
import Swal from "sweetalert2";
import axios from 'axios'
import documentService from "../../services/documentService";
import DocumentPreviewModal from "./documentPreviewModal";
import Switch, { switchClasses } from '@mui/joy/Switch';
import { toast } from 'react-toastify';


function Documents() {

    const navigate = useNavigate();
    const initialized = useRef(false)
    const [sending, setSending] = useState(false)
    const [listDocuments, setListDocuments] = useState([])
    const [listDocumentsLinks, setListDocumentsLinks] = useState([])
    const [listDocumentsMeta, setListDocumentsMeta] = useState([])
    const [documentSelected, setDocumentSelected] = useState(null);

    const [searchActive, setSearchActive] = useState('1')

    useEffect(() => {
        if (!initialized.current) {
            initialized.current = true
            doSearch()
        }
    }, []);

    const doSearch = async (event) => {
        // event.preventDefault();
        try {  
            setSending(true)
            const response = await documentService.getDocumentSearch(searchActive)
            setListDocuments(response.data)
            setListDocumentsLinks(response.links)
            setListDocumentsMeta(response.meta)            
            setSending(false)
        } catch (e) {
            if (axios.isAxiosError(e)) {
                const statusCode = e.response?.status
                const error = e.response?.data.errors;
                
                if(statusCode && statusCode !== 401){
                    Swal.fire({
                        title: 'ERROR',
                        text: error[0].detail,
                        icon: 'error',
                        confirmButtonText: 'OK',
                        confirmButtonColor: '#f13939'
                    })
                }else{
                    localStorage.removeItem("userProfile");
                    navigate('/')
                    window.location.reload();
                }
            }
            setSending(false)
        }
    }

    const trgDocumentState = async (id) => {
        Swal.fire({
            title: 'Atención',
            text: "Está seguro que quiere cambiar el estado del Documento?",
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Confirmar',
            confirmButtonColor: '#f8bb86',
            reverseButtons: true
        })
        .then(async (result) => {
            if (result.isConfirmed) {               
                try {  
                    setSending(true)
                    const response = await documentService.putDocumentStatus(id)
                    toast.success('Documento actualizado!', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    })
                    doSearch()
                    setSending(false)
                } catch (e) {
                    if (axios.isAxiosError(e)) {
                        const statusCode = e.response?.status
                        const error = e.response?.data.errors;
                        
                        if(statusCode && statusCode !== 401){
                            Swal.fire({
                                title: 'ERROR',
                                text: error[0].detail,
                                icon: 'error',
                                confirmButtonText: 'OK',
                                confirmButtonColor: '#f13939'
                            })
                        }else{
                            localStorage.removeItem("userProfile");
                            navigate('/')
                            window.location.reload();
                        }
                    }
                    setSending(false)
                }
            }
          })
    }

    const doPagination = async () => {
        try {  
            setSending(true)
            const response = await documentService.getDocumentSearchPagination(listDocumentsLinks.next)
            response.data.forEach( data => listDocuments.push(data) )
            setListDocumentsLinks(response.links)
            setListDocumentsMeta(response.meta)
            setSending(false)
        } catch (e) {
            if (axios.isAxiosError(e)) {
                const statusCode = e.response?.status
                const error = e.response?.data.errors;
                
                if(statusCode && statusCode !== 401){
                    Swal.fire({
                        title: 'ERROR',
                        text: error[0].detail,
                        icon: 'error',
                        confirmButtonText: 'OK',
                        confirmButtonColor: '#f13939'
                    })
                }else{
                    localStorage.removeItem("userProfile");
                    navigate('/')
                    window.location.reload();
                }
            }
            setSending(false)
        }
    }
   
    

    return (
        <div className="container-fluid flex-grow-1 container-p-y">

            <div className="row mb-3">
                <div className="col">
                    <h2>Documentos  { listDocumentsMeta.total > 0 && <span className="fontMedium">({listDocumentsMeta.total})</span> } </h2>
                </div>
                <div className="col">
                    <div className="btn-group float-end" role="group" aria-label="First group">
                        <button type="button" className="btn btn-outline-secondary" title="Nuevo envio">
                            <i className="tf-icons bx bx-plus" />
                        </button>
                        <button type="button" className="btn btn-outline-secondary" title="Buscar" data-bs-toggle="offcanvas"
                          data-bs-target="#offcanvasEnd"
                          aria-controls="offcanvasEnd">
                            <i className="tf-icons bx bx-filter" />
                        </button>
                    </div>
                </div>
            </div>
        

            <div className="card">

                <div className="table-responsive ">
                    <table className="table table-striped table-hover">
                        <thead>
                            <tr className="text-nowrap">
                                <th>Id</th>
                                <th>Nombre</th>
                                <th>Tipo</th>
                                <th>Estado</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody className="table-border-bottom-0">
                            {listDocuments.map(document => {
                                return (
                                    <tr key={document.id}>
                                        <td>{document.id}</td>
                                        <td>{document.display_name}</td>
                                        <td>{document.sworn_statement_type.display_name.toUpperCase()}</td>
                                        <td>
                                            <Switch 
                                                id={"chk-"+document.id}
                                                size="md"  
                                                checked={document.active}
                                                onChange={() => trgDocumentState(document.id)}
                                                sx={(theme) => ({
                                                    display: 'inherit',
                                                    '--Switch-trackWidth': '40px',
                                                    '--Switch-trackHeight': '20px',
                                                    '--Switch-thumbSize': '12px',
                                                    '--Switch-thumbBackground': 'rgb(96, 94, 92)',
                                                    '--Switch-trackBorderColor': 'rgb(96, 94, 92)',
                                                    '--Switch-trackBackground': '#dfdfdf',
                                                    // '--Switch-trackBackground': theme.vars.palette.background.body,
                                                    '&:hover': {
                                                      '--Switch-trackBorderColor': 'rgb(50, 49, 48)',
                                                    //   '--Switch-trackBackground': theme.vars.palette.background.body,
                                                      '--Switch-trackBackground': '#dfdfdf',
                                                    },
                                                    [`&.${switchClasses.checked}`]: {
                                                      '--Switch-trackBackground': '#03c3ec',
                                                      '&:hover': {
                                                        '--Switch-trackBackground': '#106EBE',
                                                      },
                                                    },
                                                    [`&.${switchClasses.disabled}`]: {
                                                      '--Switch-thumbColor': '#C8C6C4',
                                                      '--Switch-trackBorderColor': '#C8C6C4',
                                                    },
                                                    [`&.${switchClasses.disabled}.${switchClasses.checked}`]: {
                                                      '--Switch-trackBackground': '#C8C6C4',
                                                      '--Switch-thumbColor': '#F3F2F1',
                                                    },
                                                    [theme.getColorSchemeSelector('dark')]: {
                                                      '--Switch-trackBorderColor': 'rgb(161, 159, 157)',
                                                      '--Switch-trackBackground': 'rgb(27, 26, 25)',
                                                      '--Switch-thumbBackground': 'rgb(161, 159, 157)',
                                                      '&:hover': {
                                                        '--Switch-trackBorderColor': '#fff',
                                                        '--Switch-thumbBackground': '#fff',
                                                      },
                                                      [`&.${switchClasses.checked}`]: {
                                                        '--Switch-trackBackground': '#83766d',
                                                        // '--Switch-trackBackground': 'rgb(40, 153, 245)',
                                                        '--Switch-thumbBackground': 'rgb(27, 26, 25)',
                                                        '&:hover': {
                                                          '--Switch-trackBackground': 'rgb(108, 184, 246)',
                                                        },
                                                      },
                                                    },
                                                  })}
                                            />
                                        </td>
                                        <td className="text-end">
                                            <div className="dropdown">
                                                <button type="button" className="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown">
                                                    <i className="bx bx-dots-vertical-rounded" />
                                                </button>
                                                <div className="dropdown-menu">
                                                    <a className="dropdown-item" href="#"> Editar</a>
                                                    <a className="dropdown-item" href="#" 
                                                        onClick={()=> setDocumentSelected(document) }
                                                        data-bs-toggle="modal" data-bs-target="#documentPreviewModal"> Vista previa</a>
                                                    <a className="dropdown-item" href="#"> Clonar Documento</a>
                                                    
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}
                            
                        </tbody>
                    </table>
                </div>
            </div>
            { listDocumentsLinks.next !== null ?
                    <div className="row mt-4">
                        <div className="col d-grid gap-2 d-md-flex justify-content-md-center">
                        { sending ? 
                            <div className="d-flex justify-content-center">
                                <div className="spinner-border text-info" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            </div>
                        : 
                            <button type="button" className="btn btn-outline-info" onClick={doPagination}>Mas Información</button>
                        }
                        </div>
                    </div>
                :
                    <span></span>
                }
            
            {/* SEARCH */}
            <div>
                <div
                    className="offcanvas offcanvas-end"
                    tabIndex="-1"
                    id="offcanvasEnd"
                    aria-labelledby="offcanvasEndLabel"
                >
                    <div className="offcanvas-header">
                        <h5 id="offcanvasEndLabel" className="offcanvas-title">
                            <i className="tf-icons bx bx-filter" /> Buscar
                        </h5>
                        <button
                            type="button"
                            className="btn-close text-reset"
                            data-bs-dismiss="offcanvas"
                            aria-label="Close"
                        ></button>
                    </div>
                    <div className="offcanvas-body ">
                        <form>
                            <div className="mb-3">
                                <label htmlFor="exampleFormControlSelect1" className="form-label">Estado</label>
                                <select className="form-select" defaultValue={''} onChange={(e)=> setSearchActive(e.target.value)}>
                                    <option value="1">Habilitado</option>
                                    <option value="0">Deshabilitado</option>
                                </select> 
                            </div>
                        </form>

                        <div className="mt-5">
                            { sending ? 
                                <div className="d-flex justify-content-center">
                                    <div className="spinner-border text-info" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                    </div>
                                </div>
                            : 
                                <div>
                                    <button type="button" className="btn btn-info mb-2 d-grid w-100" onClick={doSearch}>Buscar</button>
                                    <button type="button" className="btn btn-outline-secondary d-grid w-100" data-bs-dismiss="offcanvas" >
                                        Cerrar
                                    </button>
                                </div>
                            }
                            
                        </div>
                        
                    </div>
                </div>
            </div>
                        
            {/* MODAL */}
            {/* <DocumentPreviewModal document={documentSelected} />  */}

        </div>             
    );

}

export default Documents;