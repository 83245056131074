import React, {useCallback, useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom"
import Swal from "sweetalert2";
import axios from 'axios'
import Divider from '@mui/joy/Divider';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import Grid from '@mui/joy/Grid';
import Input from '@mui/joy/Input';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Autocomplete from '@mui/joy/Autocomplete';
import Textarea from '@mui/joy/Textarea';
import Typography from '@mui/joy/Typography';
import Table from '@mui/joy/Table';
import FormHelperText from '@mui/joy/FormHelperText';
import { NumericFormat } from 'react-number-format';
import Loading from "../../components/loading";
import { toast } from 'react-toastify';
//SERVICES  
import SecuritieService from "../../services/securitieService";
import AccountSeervice from "../../services/accountService";
import ListService from "../../services/listService";
import ExchangeOperationService from "../../services/exchangeOperationService";



function OperationsInstrumento() {


    const initPreview = {
        comitente: "",
        codComitente: '',
        comitenteDesc: '',
        operationType: '',
        segment: '',
        term: '',
        ticker: '',
        quantity: '',
        pricetype: 2,
        currency: '',
        base: '',
        esco: '',
        spread: '',
        observations: '',
        accountManager: '',
        contraparte: {id: 1, name: ''},
    }
    const navigate = useNavigate();
    const [cargando, setCargando] = useState(true)
    const [listAccounts, setListAccounts] = useState([])
    const [listEspecies, setListEspecies] = useState([])
    const [previewModel, setPreviewModel] = useState(initPreview)
    const [listSegments, setListSegments] = useState([])
    const [listTerms, setListTerms] = useState([])
    const [listPorfolio, setListPortfolio] = useState([])
    const [listOperationTypes, setListOperationTypes] = useState([])
  
    useEffect(() => {
        if(cargando){
            doLoad()
        }
    }, []);


    const doLoad = useCallback(
        async (event) => {
            try {  
                // Comitentes
                const responseAccount = await AccountSeervice.getAccountAutocomplete()
                setListAccounts(responseAccount.data)     
                // Especies
                const responseSecurities = await SecuritieService.getSecuritiesAutocomplete()
                setListEspecies(responseSecurities.data)   
                // Portfolio Managers
                const responsePortfolio = await ListService.getPortfolioManagersList()
                setListPortfolio(responsePortfolio.data)   
                // Segments
                const responseSegments = await ListService.getSegmentList()
                const temSegments = []
                responseSegments.data.forEach( function(item, key) {
                    if(item.id != 3){ temSegments.push({id: item.id , name: item.name}) }
                });
                setListSegments(temSegments)
                // Terms
                const responseTerms = await ListService.getTermList()
                const tempTerms = []
                responseTerms.data.forEach( function(item, key) {
                    if(item.name === "T0" || item.name === "T1"){
                        tempTerms.push({id: item.id, name: item.name});
                    }
                });
                setListTerms(tempTerms)
                // Operation Types
                const responseOpeType = await ListService.getOperationTypesList(1)
                const tempOType = []
                responseOpeType.data.forEach( function(item, key) {
                    tempOType.push({id: item.id, name: item.name});
                });
                setListOperationTypes(tempOType)
            } catch (e) {
                if (axios.isAxiosError(e)) {
                    const statusCode = e.response?.status
                    const error = e.response?.data.errors;
                    
                    if(statusCode && statusCode !== 401){
                        Swal.fire({
                            title: 'ERROR',
                            text: error[0].detail,
                            icon: 'error',
                            confirmButtonText: 'OK',
                            confirmButtonColor: '#f13939'
                        })
                    }else{
                        localStorage.removeItem("userProfile");
                        navigate('/')
                        window.location.reload();
                    }
                }
            } finally{ setCargando(false) }
        },[]
    )

    const selectComitente = async (obj) => {
        if(obj !== null){
            setPreviewModel({
                ...previewModel,
                comitente: obj,
                codComitente: obj.account,
                comitenteDesc: obj.name,
                accountManager: obj.portfolio_manager,
            })
       }else{
            setPreviewModel({
                ...previewModel,
                comitente: "",
                codComitente: "",
                comitenteDesc: "",
                accountManager: "",
            })
       }   
    }

    const selectEspecie = async (obj) => {
        if(obj !== null){
            setPreviewModel({
                ...previewModel,
                ticker: obj,
                currency: obj.monedaDescripcion
            })
       }else{
            setPreviewModel({
                ...previewModel,
                ticker: "",
                currency: "",
            })
       }
    } 

    const selectContraparte = async (obj) => {
        if(obj !== null){
            setPreviewModel({
                ...previewModel,
                contraparte: {id: obj.id, name: obj.name},
            })
       }else{
            setPreviewModel({
                ...previewModel,
                contraparte: "",
            })
       }
    } 
    /* const selectOperationType = async (obj) => {
        if(obj !== null){
            setPreviewModel({
                ...previewModel,
                operationType: {id: obj.id, name: obj.name},
            })
       }else{
            setPreviewModel({
                ...previewModel,
                operationType: "",
            })
       }
    }  */

    const validateForm = function(){
        let rta = true;
        if(previewModel.codComitente === '' || previewModel.codComitente === undefined  || previewModel.codComitente === null){ return false; } 
        if(previewModel.ticker  === '' || previewModel.ticker === undefined  || previewModel.ticker === null){ return false; } 
        if(previewModel.operationType === '' || previewModel.operationType === undefined  || previewModel.operationType === null){ return false; } 
        if(previewModel.segment === '' || previewModel.segment === undefined  || previewModel.segment === null){ return false; } 
        if(previewModel.term === '' || previewModel.term === undefined  || previewModel.term === null){ return false; } 
        if(previewModel.contraparte.id === '' || previewModel.contraparte.id === undefined  || previewModel.contraparte.id === null){ return false; } 
        if(previewModel.quantity === '' || previewModel.quantity === undefined  || previewModel.quantity === null){ return false; } 
        if(previewModel.base === '' || previewModel.base === undefined  || previewModel.base === null){ return false; } 
        if(previewModel.esco === '' || previewModel.esco === undefined  || previewModel.esco === null){ return false; } 
        return rta;
    }

    const doOperation = function(){
        try {  
            setCargando(true)
            if(validateForm()){
                Swal.fire({
                    title: "Atención!",
                    text: "Ud. esta seguro que quiere crear la operación ?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "Crear",
                    confirmButtonColor: "#83766d"
                }).then((result) => {
                    if (result.isConfirmed) {
                        let model = {
                            settlement_term_id: previewModel.term.id,
                            account: previewModel.comitente.account,
                            operation_type_id: previewModel.operationType.id,
                            currency_id: previewModel.ticker.codMoneda,
                            segment_id: previewModel.segment.id,
                            ticker: previewModel.ticker.instrumentoAbrev,
                            price: previewModel.base,
                            qty: previewModel.quantity ,
                            esco_price: previewModel.esco,
                            observations: previewModel.observations,
                            counterparty_id: previewModel.contraparte.id, //OJOOOOO
                            currency_from_id: 1, //OJOOOOO
                            currency_to_id: 2, //OJOOOOO
                        }
                        ExchangeOperationService.setOperations(model)
                            .then((response) => {
                                toast.success('Operación creada!', {
                                    position: "top-right",
                                    autoClose: 3000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                })
                                setPreviewModel(initPreview)
                                // window.location.reload();
                            })
                            .catch((error) => {
                                if(error.response.status !== 401){
                                    Swal.fire({
                                        title: 'Ooops?',
                                        text: error.response.data.errors[0].detail,
                                        icon: 'error',
                                        confirmButtonText: 'OK',
                                        confirmButtonColor: '#f13939'
                                    })
                                }else{
                                    localStorage.removeItem("userProfile");
                                    navigate('/')
                                    window.location.reload();
                                }
                            })
                            .finally(() => { setCargando(false) })
                    } 
                });
                
            }else{
                Swal.fire({
                    title: 'Ooops?',
                    text: 'Debe completar los datos correctamente!',
                    icon: 'error',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#f13939'
                })
            }
            
        } catch (e) {
            if (axios.isAxiosError(e)) {
                const statusCode = e.response?.status
                const error = e.response?.data.errors;
                
                if(statusCode && statusCode !== 401){
                    Swal.fire({
                        title: 'ERROR',
                        text: error[0].detail,
                        icon: 'error',
                        confirmButtonText: 'OK',
                        confirmButtonColor: '#f13939'
                    })
                }else{
                    localStorage.removeItem("userProfile");
                    navigate('/')
                    window.location.reload();
                }
            }
        } finally{ setCargando(false) }
    }

    const doDraft = function(){
        try {  
            setCargando(true)
        } catch (e) {
            if (axios.isAxiosError(e)) {
                const statusCode = e.response?.status
                const error = e.response?.data.errors;
                
                if(statusCode && statusCode !== 401){
                    Swal.fire({
                        title: 'ERROR',
                        text: error[0].detail,
                        icon: 'error',
                        confirmButtonText: 'OK',
                        confirmButtonColor: '#f13939'
                    })
                }else{
                    localStorage.removeItem("userProfile");
                    navigate('/')
                    window.location.reload();
                }
            }
        } finally{ setCargando(false) }
    }

    if(cargando){
        return(
            <Loading></Loading>
        )
    }

    return(
        <div className="row">
            <div className="col-sm-8" style={{paddingLeft: 0}}>
                <div className="card">
                    <div className="card-body" style={{paddingBottom:0}}>
                        {/* <h5 className="card-title">Operaciones con Bonos</h5> */}
                        <h6 className="card-subtitle text-muted">Operaciones de intermediación por instrumentos</h6>
                    </div>
                    
                    <Grid className="card-body" container spacing={2} sx={{ flexGrow: 1 }}>        
                        <Grid xs={6}>
                            <FormControl>
                                <FormLabel className="form-labels">Comitente <span style={{color:'red'}}>*</span></FormLabel>
                                <Autocomplete 
                                    autoFocus
                                    options={listAccounts} 
                                    getOptionLabel={option => option.name +' - '+ option.account }
                                    slotProps={{
                                        listbox: {
                                            sx: (theme) => ({
                                            zIndex: theme.vars.zIndex.modal
                                            })
                                        }
                                    }}
                                    placeholder="Seleccione un Comitente" 
                                    onChange={(event, newValue) => { selectComitente(newValue); }}
                                />  
                            </FormControl>
                        </Grid>
                        <Grid xs={6}>
                            <FormControl>
                                <FormLabel className="form-labels">Oficial de Cuenta <span style={{color:'red'}}>*</span></FormLabel>
                                <Input type="text" size="md" placeholder="" disabled={true} value={previewModel.accountManager}/>
                            </FormControl>
                        </Grid>
                        <Grid xs={4}>
                            <FormControl>
                                <FormLabel className="form-labels">Segmento <span style={{color:'red'}}>*</span></FormLabel>
                                <Select 
                                        // placeholder="Seleccione un Segmento" 
                                        placeholder="" 
                                        defaultValue={previewModel.segment}
                                        onChange={(event, newValue) => { setPreviewModel({...previewModel, segment: newValue}) }}
                                >
                                    {listSegments.map( (segment) => 
                                        <Option value={segment} key={segment.id}>{segment.name}</Option>
                                    )}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid xs={4}>
                            <FormControl>
                                <FormLabel className="form-labels">Tipo Operación<span style={{color:'red'}}>*</span></FormLabel>
                                <Select 
                                    // placeholder="Seleccione Tipo Operación" 
                                    value={previewModel.operationType}
                                    onChange={(event, newValue) => { setPreviewModel({...previewModel, operationType: newValue}) }}
                                    // onChange={(event, newValue) => { selectOperationType(newValue)}}
                                >
                                    {listOperationTypes.map( (otype) => 
                                        <Option value={otype} key={otype.id}>{otype.name}</Option>
                                    )}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid xs={4}>
                            <FormControl>
                                <FormLabel className="form-labels">Plazo de Liquidacion <span style={{color:'red'}}>*</span></FormLabel>
                                <Select 
                                        placeholder="" 
                                        onChange={(event, newValue) => setPreviewModel({...previewModel, term: newValue}) }
                                        value={previewModel.term}
                                >
                                    {listTerms.map( (term) => 
                                        <Option value={term} key={term.id} label={term.name}>{term.name}</Option>
                                    )}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid xs={4}>
                            <FormControl>
                                <FormLabel className="form-labels">Contraparte <span style={{color:'red'}}>*</span></FormLabel>
                                <Select 
                                    onChange={(event, newValue) => { selectContraparte(newValue); }}
                                    value={previewModel.contraparte.name}
                                >
                                    {listPorfolio.map( (manager) => 
                                        <Option value={manager} key={manager.id} label={manager.name}>{manager.name}</Option>
                                    )}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid xs={4}>
                            <FormControl>
                                <FormLabel className="form-labels">Especie </FormLabel>
                                <Autocomplete
                                    options={listEspecies} 
                                    getOptionLabel={option => option.instrumentoAbrev +'('+ option.monedaDescripcion+')' }
                                    slotProps={{
                                        listbox: {
                                            sx: (theme) => ({
                                            zIndex: theme.vars.zIndex.modal
                                            })
                                        }
                                    }}
                                    placeholder="Seleccione una Especie" 
                                    onChange={(event, newValue) => { selectEspecie(newValue); }}
                                />  
                            </FormControl>
                        </Grid>
                        <Grid xs={4}>
                            <FormControl>
                                <FormLabel className="form-labels">Moneda <span style={{color:'red'}}>*</span></FormLabel>
                                <Input size="md" placeholder="" disabled={true} value={previewModel.currency}/>
                            </FormControl>
                        </Grid>
                        <Grid xs={4}>
                            <FormControl>
                                <FormLabel className="form-labels">Cantidad <span style={{color:'red'}}>*</span></FormLabel>
                                <Input type="number" size="md" placeholder="" 
                                    value={previewModel.quantity}
                                    slotProps={{
                                        input: {
                                        min: 0,
                                        },
                                    }} 
                                    onChange={(event) => {setPreviewModel({...previewModel, quantity: event.target.value}); }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid xs={4}>
                            <FormControl>
                                <FormLabel className="form-labels">Precio Base <span style={{color:'red'}}>*</span></FormLabel>
                                <Input type="number" size="md" placeholder="" 
                                    value={previewModel.base}
                                    slotProps={{
                                        input: {
                                        min: 0,
                                        },
                                    }} 
                                    onChange={(event) => {setPreviewModel({...previewModel, base: event.target.value});}}
                                />
                            </FormControl>
                        </Grid>
                        <Grid xs={4}>
                            <FormControl>
                                <FormLabel className="form-labels">Precio ESCO <span style={{color:'red'}}>*</span></FormLabel>
                                <Input type="number" size="md" placeholder="" 
                                    value={previewModel.esco}
                                    slotProps={{
                                        input: {
                                        min: 0,
                                        },
                                    }} 
                                    onChange={(event) => {setPreviewModel({...previewModel, esco: event.target.value}); }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid xs={12}>
                            <FormControl>
                                <FormLabel className="form-labels">Observciones</FormLabel>
                                <Textarea
                                    onChange={(event) => { setPreviewModel({...previewModel, observations: event.target.value}) }}
                                    placeholder="Escriba aqui..."
                                    minRows={4}
                                    maxRows={4}
                                    value={previewModel.observations}
                                />
                            </FormControl>
                        </Grid>

                        <Typography level="body-xs" style={{color:'red'}}>* obligatorios</Typography>
                    </Grid>
                </div>
            </div>
            <div className="col-sm-4">
                <div className="card">
                    <div className="card-body" style={{paddingBottom:0}}>
                        {/* <h5 className="card-title">Vista Previa</h5> */}
                        <h6 style={{color:'red'}} className="card-subtitle text-muted">Vista Previa</h6>
                    </div>

                    <Grid className="card-body" container spacing={2} sx={{ flexGrow: 1 }}>
                        <Grid xs={12}>
                            <FormLabel className="form-labels">Comitente</FormLabel>  
                            <Typography level="h2" sx={{ fontSize: 'xl', mb: 0.5 }}>{previewModel.codComitente} - {previewModel.comitenteDesc}</Typography>
                            <Divider />
                        </Grid>

                        <Table>
                            <tbody>
                                <tr key="accountmanager">
                                    <td><span className="form-labels">Oficial de Cuenta</span>  </td>
                                    <td><Typography level="title-sm">{previewModel.accountManager}</Typography></td>
                                </tr>
                                <tr key="segmento">
                                    <td><span className="form-labels">Segmento</span>  </td>
                                    <td><Typography level="title-sm">{previewModel.segment.name}</Typography></td>
                                </tr>
                                <tr key="toperacion">
                                    <td><span className="form-labels">Tipo de Operación</span>  </td>
                                    <td><Typography level="title-sm">{previewModel.operationType.name}</Typography></td>
                                </tr>
                                <tr key="plazo">
                                    <td><span className="form-labels">Plazo de liquidación</span>  </td>
                                    <td><Typography level="title-sm">{previewModel.term.name}</Typography></td>
                                </tr>
                                <tr key="contraparte">
                                    <td><span className="form-labels">Contraparte</span>  </td>
                                    <td><Typography level="title-sm">{previewModel.contraparte.name}</Typography></td>
                                </tr>
                                <tr key="ticker">
                                    <td><span className="form-labels">Especie</span> </td>
                                    <td>
                                        <Typography level="title-sm">{previewModel.ticker.instrumentoAbrev}</Typography>
                                        <Typography level="body-xs">{previewModel.ticker.instrumentoDesc}</Typography>
                                    </td>
                                </tr>
                                <tr key="currency">
                                    <td><span className="form-labels">Moneda</span>  </td>
                                    <td><Typography level="title-sm">{previewModel.currency}</Typography></td>
                                </tr>
                                <tr key="qty">
                                    <td><span className="form-labels">Cantidad</span>  </td>
                                    <td>
                                        <Typography level="title-sm">
                                            <NumericFormat 
                                                displayType="text" 
                                                value={previewModel.quantity} 
                                                allowLeadingZeros thousandSeparator="." 
                                                decimalSeparator=","
                                            /> 
                                        </Typography>
                                    </td>
                                </tr>
                                <tr key="pbase">
                                    <td><span className="form-labels">Precio Base</span>  </td>
                                    <td>
                                        <Typography level="title-sm">
                                            <NumericFormat 
                                                displayType="text" 
                                                value={previewModel.base} 
                                                allowLeadingZeros thousandSeparator="." 
                                                decimalSeparator=","
                                                prefix={'$'}  
                                            /> 
                                        </Typography>
                                    </td>
                                </tr>
                                <tr key="pesco">
                                    <td><span className="form-labels">Precio ESCO</span>  </td>
                                    <td>
                                        <Typography level="title-sm">
                                            <NumericFormat 
                                                displayType="text" 
                                                value={previewModel.esco} 
                                                allowLeadingZeros thousandSeparator="." 
                                                decimalSeparator=","
                                                prefix={'$'}  
                                            /> 
                                        </Typography>
                                    </td>
                                </tr>
                                <tr key="spread">
                                    <td><span className="form-labels">Spread</span>  </td>
                                    <td>
                                        {previewModel.operationType === 'Compra' ?
                                            (
                                                <span>
                                                    <Typography level="title-sm">
                                                        <NumericFormat 
                                                            displayType="text" 
                                                            value={previewModel.base - previewModel.esco} 
                                                            allowLeadingZeros thousandSeparator="." 
                                                            decimalSeparator=","
                                                            prefix={'$'}  
                                                        /> 
                                                    </Typography>
                                                    <FormHelperText><small>(Precio Base - Precio ESCO)</small></FormHelperText>
                                                </span>
                                            )
                                        :
                                            (
                                                <span>
                                                    <Typography level="title-sm">
                                                        <NumericFormat 
                                                            displayType="text" 
                                                            value={previewModel.esco - previewModel.base} 
                                                            allowLeadingZeros thousandSeparator="." 
                                                            decimalSeparator=","
                                                            prefix={'$'}  
                                                        /> 
                                                    </Typography>
                                                    <FormHelperText><small>(Precio ESCO - Precio Base)</small></FormHelperText>
                                                </span>
                                            )
                                            
                                        }
                                    </td>
                                </tr>
                                <tr key="total">
                                    <td><span className="form-labels">Total Burto Operación</span>  </td>
                                    <td>
                                        <Typography level="title-sm">
                                            <NumericFormat 
                                                displayType="text" 
                                                value={previewModel.esco * previewModel.quantity} 
                                                allowLeadingZeros thousandSeparator="." 
                                                decimalSeparator=","
                                                prefix={'$'}  
                                            /> 
                                        </Typography>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                        {/* <Grid xs={6} >
                            <button type="button" className="btn btn-outline-dark" onClick={doDraft}>GUARDAR BORRADOR</button>
                        </Grid> */}
                        <Grid xs={6}>
                            <button type="button" className="btn btn-dark" onClick={doOperation}>CREAR OPERACION</button>
                        </Grid>
                        
                        
                    </Grid>
                </div>
            </div>
        </div>

    );


}

export default OperationsInstrumento;