import api from "../apiapp";

const SecurityExchangeOperationService = {

  setOperations: async function (model) {
    const response = await api.request({
      url: 'exchange-operations',
      method: "POST",
      data: model
    })
    
    return response.data
  },
  getOperationsDay: async function () {
    const response = await api.request({
      url: 'exchange-operations/reports/today?sort=-date&include=accountModel,user,settlementTerm,operationType,currency,market,segment,priceType,operationState',
      method: "GET",
    })
  
    return response.data
  },
  

}

export default SecurityExchangeOperationService;