import React, {useEffect, useRef, useState} from "react";
// import {useNavigate} from "react-router-dom"
// import Grid from '@mui/joy/Grid';
import Tabs from '@mui/joy/Tabs';
import TabList from '@mui/joy/TabList';
import Tab, { tabClasses } from '@mui/joy/Tab';
import TabPanel from '@mui/joy/TabPanel';
import moment from 'moment';
import MesaDayOperationsBonos from "./mesa-day-operations-bonos";
import MesaDayOperationsFCI from "./mesa-day-operations-fci";
import MesaDayOperationsInterm from "./mesa-day-operations-interm";


function MesaOperations() {

    const defaultDate = new Date().toISOString().slice(0,10);

    return (
        <div className="container-fluid flex-grow-1 container-p-y">
            
            <div className="row">
                <h4 className="fw-bold py-3 mb-2"><span className="text-muted fw-light">Mesa /</span> Operaciones del día ({moment(defaultDate).format('D MMMM')})</h4>
                {/* <h4 class="fw-bold py-3 mb-2"><span class="text-muted fw-light">Mesa /</span> Operaciones del día ({moment(defaultDate).format('DD/MM/YYYY')})</h4> */}
                <Tabs aria-label="tabs" defaultValue={0} sx={{ bgcolor: 'transparent' }} >
                    <TabList
                        disableUnderline
                        sx={{
                        p: 0.5,
                        gap: 0.5,
                        borderRadius: 'xl',
                        // bgcolor: 'background.level1',
                        bgcolor: 'rgba(131, 118, 109, 0.16)',
                        [`& .${tabClasses.root}[aria-selected="true"]`]: {
                            boxShadow: 'sm',
                            bgcolor: 'background.surface',
                            // bgcolor: '#FFFFFF',
                        },
                        }}
                    >
                        <Tab disableIndicator>Bonos</Tab>
                        <Tab disableIndicator>FCI</Tab>
                        <Tab disableIndicator>Intermediación</Tab>
                    </TabList>
                            
                    <TabPanel value={0}>
                        <MesaDayOperationsBonos />
                    </TabPanel>

                    <TabPanel value={1}>
                        <MesaDayOperationsFCI />
                    </TabPanel>

                    <TabPanel value={2}>
                        <MesaDayOperationsInterm />
                    </TabPanel>

                </Tabs>
            </div>
            
    

        </div>   
    );

}

export default MesaOperations;