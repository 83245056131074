import React from "react";
import Modal from '@mui/joy/Modal';
import ModalClose from '@mui/joy/ModalClose';
import ModalDialog from '@mui/joy/ModalDialog';
import DialogTitle from '@mui/joy/DialogTitle';
import DialogContent from '@mui/joy/DialogContent';
import Divider from '@mui/joy/Divider';
import Grid from '@mui/joy/Grid';

function ModalViewAccount( {handleOpen, isOpen, modelSelected}) {



    return (
        <Modal
            aria-labelledby="close-modal-title"
            open={isOpen}
            onClose={() => handleOpen(false) }
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <ModalDialog>
                <ModalClose variant="outlined" />
                <DialogTitle>  Ver Comitente</DialogTitle>
                <Divider />
                <DialogContent>
                    <Grid container spacing={2} sx={{ flexGrow: 1 }}>
                        <Grid xs={6}>
                            <div>
                                <label className="form-label" htmlFor="basic-default-nombre-selected">Código</label>
                                <input type="text" className="form-control" id="basic-default-nombre-selected" placeholder=""                                
                                    value={modelSelected.account} disabled={true}
                                />
                            </div>
                        </Grid>
                        <Grid xs={6}>
                            <div>
                                <label className="form-label" htmlFor="basic-default-codigo-selected">Nombre</label>
                                <input type="text" className="form-control" id="basic-default-codigo-selected" placeholder=" "                                
                                    value={modelSelected.name} disabled={true}
                                />
                            </div>
                        </Grid>
                        <Grid xs={6}>
                            <div>
                                <label className="form-label" htmlFor="basic-default-codigo-selected">Adm. Cartera</label>
                                <input type="text" className="form-control" id="basic-default-codigo-selected" placeholder=" "                                
                                    value={modelSelected.portfolio_manager} disabled={true}
                                />
                            </div>
                        </Grid>
                        <Grid xs={6}>
                            <div>
                                <label className="form-label" htmlFor="basic-default-codigo-selected">Mail</label>
                                <input type="text" className="form-control" id="basic-default-codigo-selected" placeholder=""                                
                                    value={modelSelected.emails} disabled={true}
                                />
                            </div>
                        </Grid>
                        
                    </Grid>
                </DialogContent>
            </ModalDialog>
        </Modal>

    );
}

export default ModalViewAccount;