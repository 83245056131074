import React, {useState, useEffect, useRef} from "react";
import Modal from '@mui/joy/Modal';
import ModalClose from '@mui/joy/ModalClose';
import ModalDialog from '@mui/joy/ModalDialog';
import DialogTitle from '@mui/joy/DialogTitle';
import DialogContent from '@mui/joy/DialogContent';
import DialogActions from '@mui/joy/DialogActions';
import Divider from '@mui/joy/Divider';
import Grid from '@mui/joy/Grid';
import Autocomplete from '@mui/joy/Autocomplete';
import { toast } from 'react-toastify';
import statementService from "../../services/statementService"


function SendDocumentModal( {handleOpen, isOpen, refresh, listStatement, listAccounts}) {
    
    const initModel = {
        comitente: '',
        email: '',
        fullname : '',
        status : '',
        statement : '',
    }
    const initialized = useRef(false)
    const [sending, setSending] = useState(false)
    const [sendModel, setSendModel] = useState(initModel)
    
    const newListAccounts = []
    listAccounts.forEach(data => newListAccounts.push(
        {
            id: data.id,
            account: data.account,
            name: data.name,
            emails: data.emails,
        }
    ))

    useEffect(() => {
        if (!initialized.current) {
            initialized.current = true
            setSendModel(initModel)
        }
    }, []);

    const doClose = async () => { 
        setSendModel(initModel)
        handleOpen(false) 
    }

    const selectComitente = async (obj) => {
        if(obj === null || obj === undefined || obj === ''){ 
            setSendModel({
                ...sendModel,
                comitente: '',
                email: '',
                fullname: '',
            })
        }else{
            setSendModel({
                ...sendModel,
                comitente: obj.account,
                email: obj.emails,
                fullname: obj.name,
            })
        }
        
    } 

    function validateNewForm (){
        let rta = true;
        if(sendModel.comitente === '' || sendModel.comitente === undefined  || sendModel.comitente === null){ return false; } 
        if(sendModel.email === '' || sendModel.email === undefined  || sendModel.email === null){ return false; } 
        if(sendModel.fullname === '' || sendModel.fullname === undefined  || sendModel.fullname === null){ return false; } 
        if(sendModel.statement === '' || sendModel.statement === undefined  || sendModel.statement === null){ return false; } 
        return rta;
    }

    const doSave = async () => {
        setSending(true)
        if(validateNewForm()){
            let model = {
                account: sendModel.comitente,
                name: sendModel.fullname,
                email: sendModel.email,
                note: sendModel.statement,
                sworn_statement_id: sendModel.statement,
            }
            const response = await statementService.sendStatements(model)
            refresh()
            doClose()
            toast.success('Notifiación enviada!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            })
            setSending(false)
        }else{
            setSending(false)
            toast.error('Cargue los datos correctamente!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });

        }
    } 

    return (
        <Modal
            aria-labelledby="close-modal-title"
            open={isOpen}
            onClose={() => handleOpen(false) }
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <ModalDialog>
                <ModalClose variant="outlined" />
                <DialogTitle>Nuevo Envio</DialogTitle>
                <Divider />
                <DialogContent>
                    <Grid container spacing={2} sx={{ flexGrow: 1 }}> 
                        <Grid xs={12}>
                            <div>
                                <label className="form-label" htmlFor="nombre">Notificación <span style={{color:'red'}}>*</span></label>
                                <select className="form-select" id="basic-default-notification" onChange={(e)=> setSendModel({...sendModel, statement: e.target.value})}>
                                    <option value="" key="null">Seleccione una Notificación</option>
                                    {listStatement.filter(st => st.active).map( (state) => 
                                        <option value={state.id} key={state.id}>{state.display_name}</option> )
                                    }
                                </select> 
                            </div>
                        </Grid>
                        <Grid xs={12}>
                            <label className="form-label" htmlFor="basic-default-comitente">Seleccione Comitente <span style={{color:'red'}}>*</span></label>
                            <Autocomplete 
                                options={newListAccounts} 
                                getOptionLabel={option => option.name +' - '+ option.account }
                                slotProps={{
                                    listbox: {
                                        sx: (theme) => ({
                                        zIndex: theme.vars.zIndex.modal
                                        })
                                    }
                                }}
                                // onChange={(option)=> selectComitente(option)}
                                placeholder="Seleccione un Comitente" 
                                onChange={(event, newValue) => { selectComitente(newValue); }}
                            />  
                        </Grid>
                        <Grid xs={12}>
                            <label className="form-label" htmlFor="basic-default-comitente-selected">Comitente</label>
                            <input type="text" className="form-control" id="basic-default-comitente-selected" placeholder="" disabled={true} value={sendModel.comitente} />
                        </Grid>
                        <Grid xs={12}>
                            <label className="form-label" htmlFor="basic-default-fullname">Nombre Completo</label>
                            <input type="text" className="form-control" id="basic-default-fullname" placeholder="" disabled={true} value={sendModel.fullname} />
                        </Grid>
                        <Grid xs={12}>
                            <label className="form-label" htmlFor="basic-default-email">Email <span style={{color:'red'}}>*</span></label>
                            <input type="text" className="form-control" id="basic-default-email" placeholder="" value={sendModel.email} />
                        </Grid>
                    </Grid>
                </DialogContent>
                <Divider />
                <DialogActions>
                    <button type="button" className="btn btn-info" onClick={() => doSave() } disabled={sending}>Confirmar</button>
                    <button type="button" className="btn btn-outline-secondary"  onClick={doClose}> Cerrar </button>                    
                </DialogActions>
            </ModalDialog>
        </Modal>
    );
}

export default SendDocumentModal;