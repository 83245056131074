import api from "../apiapp";

const MiddleOperationService = {

  setMiddleOperations: async function (model) {
    const response = await api.request({
      url: 'middle-operations',
      method: "POST",
      data: model
    })
    
    return response.data
  },
  getMiddleOperationsSearch: async function (model) {
    const response = await api.request({
      // url: 'esco-middle-operations-details?include=currency&filter[date]=2024-11-07',
      url: 'esco-middle-operations-details?include=currency&filter[date]='+model.dateFrom,
      method: "GET",
    })
    
    return response.data
  },
  importFile: async function (model) {
    const response = await api.request({
      url: 'esco-middle-operations/import-operations/excel',
      method: "POST",
      data: model
    })
    
    return response.data
  },
  getPagination: async function (url) {
    const response = await api.request({
      url: url,
      method: "GET",
    })
    
    return response.data
  },
  getMiddleOperations: async function (model) {
    var filters = '';
		if(model.dateFrom !== '' && model.dateFrom !==""){ filters += '&filter[uploadat]='+model.dateFrom; }
		// if(model.dateTo !== '' && model.dateTo !== ""){ filters += '&filter[to]='+model.dateTo; }
		// if(model.account !== '' && model.account !== null){ filters += '&filter[account]='+model.account; }
		// if(model.user !== '' && model.user !== null){ filters += '&filter[user]='+model.user; }
		if(model.status !== '' && model.status !== null){ filters += '&filter[isavailable]='+model.status; }
		
    const response = await api.request({
      // url: 'middle-operations?include=account,middleOperationType,portfolioManager,currency,user,operationType&filter[uploadat]=2024-12-09',
      // isavailable=true/false
      url: 'middle-operations?include=account,middleOperationType,portfolioManager,currency,user,operationType'+filters,
      method: "GET",
    })
    
    return response.data
  },
  getMiddleAvailableOperations: async function () {
    const response = await api.request({
      url: 'middle-operations/available-operations?include=account,middleOperationType,portfolioManager,currency,user,operationType',
      method: "GET",
    })
    
    return response.data
  },
  getMiddleOperationsConsolidated: async function () {
    const response = await api.request({
      url: 'middle-operations/consolidated-position?include=account,middleOperationType,portfolioManager,currency,user,operationType',
      method: "GET",
    })
    
    return response.data
  },
  getMiddleOperationsConsolidatedDate: async function (date) {
    const response = await api.request({
      url: 'middle-operations/consolidated-position?date='+date+'&include=account,middleOperationType,portfolioManager,currency,user,operationType',
      method: "GET",
    })
    
    return response.data
  },
  putChangeAgreementDates: async function (model) {
    const response = await api.request({
      url: 'middle-operations/change-agreement-dates',
      method: "PUT",
      data: model
    })
    
    return response.data
  },
  putChangeIsAvailable: async function (model) {
    const response = await api.request({
      url: 'middle-operations/change-is-available',
      method: "PUT",
      data: model
    })
    
    return response.data
  },
  getPagination: async function (url) {
    const response = await api.request({
      url: url,
      method: "GET",
    })
    
    return response.data
  },
  deleteMiddleOperations: async function (id) {
    const response = await api.request({
      url: 'middle-operations/'+id,
      method: "DELETE",
    })
    
    return response.data
  },
  

}

export default MiddleOperationService;